export const MENU_ITEM = {
  PROFILE_SETTINGS: 'ProfileSettings',
  RESET_PASSWORD: 'ResetPassword',
  LOG_OUT: 'LogOut',
};

export const PAGE_NAME = {
  LANDING: 'Landing',
  LANDING_ERROR:'LandingError',
  PROFILE_SETTINGS: 'UserProfileSettings',
  RESET_PASSWORD: 'ResetPassword',
};
