import { useEffect, useState } from 'react';
import userService from '../../services/userService';
import jsUtils from '../../utils/jsUtils';
import { useTranslation } from 'react-i18next';
 export const Dashboard = ({
  showToastMessage,
 }) => {
  const { t } = useTranslation();
    const [loading,setLoading]=useState('');
    useEffect(() => {
       
        const endpoint = `sso/v1/embeddedurl?app=App-QuickSight`;
        userService.getQuickSightHtmlFormatSAMLIDP(endpoint).then((response) => {
            if (response?.data && !(response?.data instanceof Error) && response?.data?.data) {
             let quickSightUrl = response?.data?.data ?? '';
         console.log(quickSightUrl);
                setLoading(quickSightUrl);
            }
             else if (response?.data && Number(response?.data.status) !== 200 && response.data.error.length > 0) {
              const errs = jsUtils.parseError(response.data.error);
             //  myWindow?.close();
              showToastMessage(errs);
            } else {
             //  myWindow?.close();
              showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
            }
          }).catch((ex) => {
         
             showToastMessage(t('AN_UNEXPECTED_ERROR_HAS_OCCURRED'));
          })
    }, [])
    return(
      loading && <iframe src={loading} width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
    )
 }
 export default Dashboard;