import {Alert, Snackbar} from "@mui/material";

const ErrorMessage = ({
  message,
  type = ERROR_TYPE.ERROR,
  onClose,
  autoHideDuration = null,
}) => {

  return (
    <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!message}
        autoHideDuration={autoHideDuration}
        onClose={onClose}>
        <Alert onClose={onClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
    </Snackbar>
  )
}

export const ERROR_TYPE = {
  ERROR : 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
}

export default ErrorMessage;
