import { Box, Button, Grid, Typography, Tooltip } from '@mui/material';
import React from 'react';
export default function Application({imgSrc,label,subText, url,navigateTo}){
    return <Grid item  xs={3}  md={3}>
    <Box className='dvGroup' onClick={(event)=>{navigateTo(url,event)}} >
      <Tooltip title={label} placement="top" arrow>
        <Button data-testid={label} variant="text"><img className='imgIconCenter'  src={imgSrc}/></Button>
      </Tooltip>
      <Typography className='landingColorText22'>{label}</Typography>  
      <Typography className='landingColorSubText'>{subText}</Typography>  
    </Box>
  </Grid>
}
